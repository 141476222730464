

export type DataType = 'text' | 'integer' | 'decimal' | 'float' | 'date' | 'boolean' | 'time' | 'enumeration' | 'entity';

//export type _ExtensionType = 'text' | 'integer' | 'decimal' | 'float' | 'date' | 'boolean' | 'time' | 'enumeration' | 'entity';

export type BaseType = 'string' | 'numeric' | 'boolean' | 'date' | 'time';

export interface IExtensionType {
    id: number,
    name: string,
    description: string,

    type: DataType,
    textLength: number,
    enumerationId: number,
    enumerationName: string,
    entityName: string,
    entityDescription: string,

    baseType: BaseType
  }

  export interface IExtension {
    id: number,
    extensionTypeId: number,
    alias: string,
    description: string,

    type: 'product' | 'productInvoice' | 'evaluationItem' | 'groupPolicy';

    productId: number,
    isRiskExtension: boolean,
    productInvoiceId: number,
    evaluationItemTypeId: number,
    
    extensionTypeName: string,
    extensionTypeType: DataType,
    extensionTypeBaseType: BaseType
  }

  export interface IExtensionValue extends IExtension {
    value: any
  }


  export const defaultExtensionType : IExtensionType = {
    id: 0,
    name: '',
    description: '',
    type: 'text',

    textLength: 0,
    enumerationId: 0,
    enumerationName: '',
    entityName: '',
    entityDescription: '',

    baseType: 'string'
  }

  export const defaultExtension : IExtension = {
    id: 0,
    extensionTypeId: 0,
    alias: '',
    description: '',

    type: 'product',

    productId: 0,
    isRiskExtension: false,
    productInvoiceId: 0,
    evaluationItemTypeId: 0,

    extensionTypeName: '',
    extensionTypeType: 'text',
    extensionTypeBaseType: 'string'
  }


  export interface IExtensionTypeSearch {
    name: string,
    description: string
  }