
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IMenu } from "components/models/Menu";

import { IoSearchCircleSharp } from 'react-icons/io5';


const _ = () => {

    const { t, i18n } = useTranslation();

    const [applicationMenus,setApplicationMenus] = useState<IMenu[]>([]);

    const menu1 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu2 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu3 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    const menu4 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu5 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu6 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    //const [applicationMenus,setApplicationMenus] = useState<IMenu[]>();

        useEffect( () => {
          setApplicationMenus(
            [
              {
                ...menu1, name: 'param', text: t('Setting'), iconName:'FiSettings', isOpen: false,
                menuItems: [
                  {name: 'applicationSetup', text: t('Company Setup'), iconName: 'MdSettingsApplications', entityName: 'ApplicationSetup', routeToGo: '/applicationSetup',   order: 1, group: 1},
                  {name: 'applicationQuery', text: t('Application Query'), iconName: 'AiOutlineConsoleSql', entityName: 'ApplicationQuery', routeToGo: '/applicationQuery',  order: 1, group: 2},
                  
                  {name: 'messageAutomation', text: t('Message automation'), iconName: 'BiBrain', entityName: 'MessageAutomation', routeToGo: '/messageAutomation',  order: 1, group: 3},
                
                
                ]
              }, 
              {
                ...menu2, name: 'security', text: t('Security'), iconName:'MdSecurity', isOpen: false,
                menuItems: [
                  {name: 'role', text: t('Role'), iconName: 'TbUserCheck', entityName: 'Role', routeToGo: '/role',  order: 1, group: 1},
                  //{name: 'group', text: 'Group', entityName: 'Role', routeToGo: '/',  order: 1, group: 1},
                  //{name: 'profile', text: 'Profile', entityName: 'Profile', routeToGo: '/',  order: 1, group: 1},
                  {name: 'user', text: t('User'), iconName: 'MdGroup', entityName: 'User', routeToGo: '/user',order: 2, group: 1},
                ]
              },
              {
                ...menu3, name: 'config', text: t('Configuration'), iconName:'MdPermDataSetting', isOpen: false,
                menuItems: [
                  {name: 'extensionType', text: t('Extension type'), iconName: 'VscExtensions' , entityName: 'ExtensionType',routeToGo: '/extensionType', order: 0, group: 1},
                  {name: 'enumeration', text: t('Codification'), iconName: 'RiQrCodeFill' , entityName: 'Enumeration',routeToGo: '/enumeration', order: 1, group: 1},
                  // {name: 'matrix', text: 'Matrix', entityName: 'Matrix',routeToGo: '/', order: 2, group: 1},
                  // {name: 'vector', text: 'Vector', entityName: 'Vector',routeToGo: '/', order: 2, group: 1},
                   {name: 'report', text: t('Report'), iconName: 'TbFileReport', entityName: 'Report',routeToGo: '/report', order: 1, group: 2},
                   {name: 'externalLibrary', text: t('Api file'), iconName: 'VscFileBinary', entityName: 'ExternalLibrary',routeToGo: '/externalLibrary', order: 2, group: 2},
                  //{name: 'tarification', text: t('Tarification'), entityName: 'Tarification',routeToGo: '/tarification', order: 1, group: 1},
                 
                 
                  //{name: 'workflow', text: 'Workflow', entityName: 'Workflow',routeToGo: '/', order: 1, group: 2},
                  //{name: 'entityData', text: 'EntityData', entityName: 'EntityData',routeToGo: '/', order: 1, group: 3},
                  //{name: 'queryData', text: 'QueryData', entityName: 'QueryData',routeToGo: '/', order: 2, group: 3},
                  //{name: 'report', text: 'Report', entityName: 'Report',routeToGo: '/', order: 1, group: 4},            
      
                ]
              },
              
              {
                ...menu4, name: 'production', text: t('Operation'), iconName:'MdProductionQuantityLimits', isOpen: false,
                menuItems: [
                  {name: 'contract', text: t('Contract'), iconName: 'FaFileContract', entityName: 'Contract',routeToGo: '/contract', order: 1, group: 1}, 
                  {name: 'message', text: t('Message'), iconName: 'MdHealing', entityName: 'Message',routeToGo: '/message', order: 1, group: 2}, 
                  {name: 'chatroom', text: t('Chat room'), iconName: 'FaComments', entityName: 'ChatRoom',routeToGo: '/chatroom', order: 2, group: 2}, 
                  
                  {name: 'contact', text: `${t('Contact')}`, iconName: 'FaSpa', entityName: 'Contact',routeToGo: '/contact', order: 3, group: 2}, 

                  {name: 'ticket', text: t('Ticket'), iconName: 'FaTicketAlt', entityName: 'Ticket',routeToGo: '/ticket', order: 3, group: 3}, 
                  {name: 'problem', text: t('Problem'), iconName: 'FaExclamationTriangle', entityName: 'Problem',routeToGo: '/problem', order: 3, group: 3}, 

                  {name: 'person', text: t('Person'), iconName: 'MdPeopleOutline', entityName: 'Person',routeToGo: '/person', order: 1, group: 4}, 
                ]
              },
              {
                ...menu5, name: 'dashboard', text: t('Dashboard'), iconName:'AiFillDashboard', isOpen: false,
                menuItems: [
                  {name: 'contractDashboard', text: t('Contract'),iconName: 'FiFileText', entityName: 'ContractDashboard',routeToGo: '/contractDashboard', order: 1, group: 1},       
                  {name: 'contractMessageDashboard', text: t('Message'),iconName: 'IoIosMail', entityName: 'ContractMessageDashboard',routeToGo: '/contractMessageDashboard', order: 1, group: 2},       
                  {name: 'ticketDashboard', text: t('Ticket'),iconName: 'IoMdClipboard', entityName: 'TicketDashboard',routeToGo: '/ticketDashboard', order: 1, group: 3},       
                  {name: 'contactDashboard', text: t('Contact'),iconName: 'MdContacts', entityName: 'ContactDashboard',routeToGo: '/contactDashboard', order: 1, group: 4},       
                  
                  //{name: 'paymentOperation', text: 'Payment Operation', entityName: 'Payment Operation',routeToGo: '/', order: 1, group: 2},
                ]
              },
              {
                ...menu6, name: 'more', text: t('More'), iconName:'CgMoreO', isOpen: false,
                menuItems: [
                  // {name: 'editionConfig', text: 'EditionConfig', entityName: 'EditionConfig',routeToGo: '/', order: 1, group: 1},       
                  // {name: 'objectEdition', text: 'ObjectEdition', entityName: 'ObjectEdition',routeToGo: '/', order: 1, group: 1}, 
                  // {name: 'routineObjectData', text: 'RoutineObjectData', entityName: 'RoutineObjectData',routeToGo: '/', order: 1, group: 1},
                  // {name: 'routineFilter', text: 'RoutineFilter', entityName: 'RoutineFilter',routeToGo: '/', order: 1, group: 1},
                  {name: 'smsApi', text: t('SmsApi'), iconName: 'TbApiApp', entityName: 'SmsApi',routeToGo: '/smsApi', order: 1, group: 2},
                 
                  {name: 'whatsAppApi', text: t('WhatsAppApi'), iconName: 'TbBrandWhatsapp', entityName: 'ApplicationSetupWhatsApp',routeToGo: '/applicationSetupWhatsApp', order: 2, group: 1},
                  {name: 'mailApi', text: t('MailApi'), iconName: 'TbBrandGmail', entityName: 'ApplicationSetupEmail',routeToGo: '/applicationSetupEmail', order: 3, group: 1},
             
                  {name: 'job', text: t('Job schedule'), iconName: 'MdScheduleSend', entityName: 'Job',routeToGo: '/job', order: 1, group: 2},
                  // {name: 'postingRule', text: 'PostingRule', entityName: 'PostingRule',routeToGo: '/', order: 1, group: 3},
                ]
              },
              ]
          );
        },[t, i18n]);

        return {
            applicationMenus, setApplicationMenus
        }
  }

  export default _;