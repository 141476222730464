//export type ReportType = 'message' | 'email' | 'file' ;

//export type MessageType = 'sms' | 'whatsApp' | 'facebook' | '' ;

//export type ReportFileType = 'layout' | 'doc' | 'excel' | '' ;

export type ReportTableType = 'query' | 'property' | 'feature' ;

export interface IReport {
    id: number,
    name: string,
    description: string,
    entityName: string,
    isDirectReport: boolean,
    //type: ReportType,

    entityDescription: string,
    currentDocumentLanguage: string,

    //currentMessageType: MessageType,
    //currentReportFileType: ReportFileType,

    reportDocuments: IReportDocument[],
    reportFields: IReportField[],

    reportTables: IReportTable[],

    currentTableIndex: number,
  }

  export interface IReportDocument {
    id: number,
    reportId: number,

    documentLanguage: string,
    
    messageFileName: string,
    messageObject: string,
    messageBody: string,
    shortMessageBody: string,    
    
    base64DocumentFile?: string | ArrayBuffer | null,
    documentFileName: string,
    
    reportProcessings: any[]
  }

  export interface IReportField {
    id: number,
    
    name: string,
    alias: string,
    format: string,
    valueExpression: string,
  }

  export interface IReportTable {
    id: number,
    reportId: number,
    
    name: string,
    alias: string,   

    type: ReportTableType,

    // businessApplicationId: number,
    // businessApplicationName: string,

    applicationQueryId: number,
    applicationQueryName: string,

    entityName: string,
    entityDescription: string,

    entityEnumerableProperty: string,
    entityEnumerablePropertyDescription: string,

    queryFeatureName: string,
    queryFeatureDescription: string,
    innerObjectTypeName: string,
    innerObjectPublicNamespace: string,

    reportTableColumns: IReportTableColumn[],
    reportTableDefaultParameters: IReportTableDefaultParameter[]
  }

  export interface IReportTableColumn {
    id: number,
    reportTableId: number,
    
    alias: string,    
    format: string,

    defaultColumnValueExpression: string
  }

   export interface IReportTableDefaultParameter {
    id: number,
    reportTableId: number,
    
    applicationQueryParameterId: number,    
    parameterValueExpression: string,

    parameterName: string,

    parameterDataType: string,
    parameterDescription: string
  }
  
  export const defaultReport : IReport = {
    id: 0,
    name: '',
    description: '',
    entityName: '',   
    isDirectReport: false,

    entityDescription: '',
    currentDocumentLanguage: 'fr-FR',
    
    reportDocuments: [],
    reportFields: [],
    reportTables: [],

    currentTableIndex: -1
  }

  export const defaultReportDocument : IReportDocument = {
    id: 0,
    reportId: 0,

    documentLanguage: 'fr-FR',
    
    messageFileName: '',
    messageObject: '',
    messageBody: '',
    shortMessageBody: '',
    
    base64DocumentFile: '',
    documentFileName: '',  
    
    reportProcessings: []
  }

  

  export interface IReportSearch {
    name: string,
    description: string
  }