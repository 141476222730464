import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm
  , Control, FieldPath, FieldValues, UseFormSetValue, UseFormWatch, UseFormGetValues } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FunctionsIcon from '@mui/icons-material/Functions';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreIcon from '@mui/icons-material/More';


import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { IEntity, IResult } from 'library/interface';

import useTicketService, { useBasicFilterTicket } from './services/Ticket';

import {  ITicket, defaultTicket, ITicketComment, ITicketResolution } from './models/Ticket';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import IEnumeration, { Enum_TICKET_CATEGORY, Enum_TICKET_RESOLUTION, Enum_TICKET_CLASS,
      Enum_TICKET_SEVERITY, Enum_TICKET_STATUS, Enum_TICKET_ENTITY, Enum_TARGET_SERVICE, IEnumerationItem } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { typographyGroupBoxStyling, justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import ExpressionBox from 'components/ui/ExpressionBox';
import EnhancedTable from 'components/ui/EnhancedTable';
import { useBasicFilterUser } from 'features/security/services/User';
import { IUser } from 'features/security/models/User';
import { useBasicFilterContact } from './services/Contact';
import { IContact } from './models/Contact';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useBasicFilterProblem } from './services/Problem';
import { IProblem } from './models/Problem';



export interface BasicTicketFormProps {
  
  control: Control<ITicket>,
 //personPath: FieldPath<O> ,  
 
  getValues: UseFormGetValues<ITicket>,
  setValue: UseFormSetValue<ITicket>,
  watch: UseFormWatch<ITicket>,
}

export function BasicTicketForm(props: BasicTicketFormProps) {

  const {setValue ,getValues, watch, control} = props;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createTicket, updateTicket} = useTicketService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTicket = useBasicFilterTicket( 
    
    async (event: React.MouseEvent<unknown>, row: ITicket) => {
      const {id, title } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  
  const [openUserFilter, setOpenUserFilter] = useState(false);
  const basicFilterUser = useBasicFilterUser( 
      (event: React.MouseEvent<unknown>, row: IUser) => {
          const {id, userDescription} = row;
          setValue('assignedUserId', id);
          setValue('assignedUserName', userDescription);

          setOpenUserFilter(false);
      }
  );

  const [openContactFilter, setOpenContactFilter] = useState(false);
  const basicFilterContact = useBasicFilterContact( 1,
      (event: React.MouseEvent<unknown>, row: IContact) => { 
          const {id, name, whatsAppId} = row;
          setValue('contactId', id);
          setValue('contactName', name);
          setValue('contactWhatsApp', whatsAppId.replace('@c.us',''));

          setOpenContactFilter(false);
      }
  );

  const [openProblemFilter, setOpenProblemFilter] = useState(false);
  const basicFilterProblem = useBasicFilterProblem( 
      (event: React.MouseEvent<unknown>, row: IProblem) => {
          const {id, title} = row;
          setValue('problemId', id); alert(title);
          setValue('problemTitle', title);

          setOpenProblemFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}

  const watchAssignedUserId = watch('assignedUserId');
  const watchProblemId = watch('problemId');


  const queryClient = useQueryClient();
  
        
    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Ticket'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_TICKET_CATEGORY, Enum_TICKET_CLASS, Enum_TICKET_SEVERITY, 
            Enum_TICKET_RESOLUTION, Enum_TICKET_STATUS, Enum_TICKET_ENTITY, Enum_TARGET_SERVICE ] ));
       
      const handleClickOpenUserFilter = (event: any) => {        
        setOpenUserFilter(true);
      }

      const handleClickRemoveUser = (event: any) => {
        setValue('assignedUserId', 0);
        setValue('assignedUserName', '');     
      }

      const handleClickOpenContactFilter = (event: any) => {        
        setOpenContactFilter(true);
      }

      const handleClickOpenProblemFilter = (event: any) => {        
        setOpenProblemFilter(true);
      }

      const handleClickRemoveProblem = (event: any) => {
        setValue('problemId', 0);
        setValue('problemTitle', '');     
      }
      

      

  return (
    
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                              
                              <Controller name={`title`} control={control}   
                                  render={({ field }) => <TextField sx={{width:`calc(50% - 8px)`}} {...field} label={`${t('Title')} `}
                                   inputProps={ { readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } } />}                                  
                              />
                              <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disabled               
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(16% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(16% - 8px)'}} />}
                                  /> )}
                              />
                              <Controller 
                                  name={`ticketClassCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(34% - 8px)'}} id="ticketClassCode"
                                      label={t('Class')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_TICKET_CLASS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                    </TextField>
                                  )}
                              />                              

                              
                             { openUserFilter && <FormDialog open={openUserFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('User filter')} onCancel={()=> {setOpenUserFilter(false);}} 
                                onClose={()=> {setOpenUserFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IUser> {...basicFilterUser } />
                            </FormDialog> }   
                            { openContactFilter && <FormDialog open={openContactFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Contact filter')} onCancel={()=> {setOpenContactFilter(false);}} 
                                onClose={()=> {setOpenContactFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IContact> {...basicFilterContact } />
                            </FormDialog> } 
                            { openProblemFilter && <FormDialog open={openProblemFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Problem')} onCancel={()=> {setOpenProblemFilter(false);}} 
                                onClose={()=> {setOpenProblemFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IProblem> {...basicFilterProblem } />
                            </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name={`description`} control={control}   
                                  render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} label={`${t('Description')} `}
                                    multiline rows={2}  inputProps={ { readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } } />}                                  
                              />                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name={`assignedUserName`} control={control} key={`user : ${watchAssignedUserId} ${getValues().assignedUserName} `}  
                                  render={({ field }) => <TextField sx={{width:'calc(50% - 8px)'}} id="assignedUserName" label={t('Assigned to')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: ( 
                                      <InputAdornment position="end">
                                        { (isFalsy(watchAssignedUserId) ||  watchAssignedUserId<=0) ? 
                                          <IconButton color="primary" onClick={handleClickOpenUserFilter}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton> :
                                          <IconButton color="primary" onClick={handleClickRemoveUser}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }                                                                                         
                                    </InputAdornment>
                                  )
                                }} />}                                  
                              />
                              
                                <Controller control={control}
                                  name='effectiveDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} 
                                      onChange={onChange}               
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='dueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Due date')} 
                                      onChange={onChange}               
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            
                            <Box sx={{ mt: 4, width: '100%' }} >
                            <Controller 
                                  name={`categoryCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="categoryCode"
                                      label={t('Category')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_CATEGORY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`severityCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="severityCode"
                                      label={t('Severity')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_SEVERITY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`problemTitle`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField sx={{width:'calc(30% - 8px)'}} id="problemTitle" label={t('Associated to problem')} 
                                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                        
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: ( 
                                            <InputAdornment position="end">
                                              { (isFalsy(watchProblemId) ||  watchProblemId<=0) ? 
                                                <IconButton color="primary" onClick={handleClickOpenProblemFilter}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton> :
                                                <IconButton color="primary" onClick={handleClickRemoveProblem}>
                                                  <RemoveCircleIcon />
                                                </IconButton>
                                              }                                                                                         
                                          </InputAdornment>
                                        )
                                        }} />
                                  )}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller 
                                  name={`ticketEntityCode`} 
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="ticketEntityCode"
                                      label={t('Entity responsible')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_ENTITY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`targetServiceCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="targetServiceCode"
                                      label={t('Target service or department')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TARGET_SERVICE ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`resolutionCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="resolutionCode"
                                      label={t('Resolution step')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_RESOLUTION ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />                              
                            </Box>                            
                            
                        </Stack>                        
                    </Grid>                   
                    
                </Grid>
            </Box>
        
  )
}
