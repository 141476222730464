
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {get} from 'lodash';

import { Controller, FieldArray, FieldArrayMethodProps, FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import TokenIcon from '@mui/icons-material/Token';

import { IApplicationSetup, defaultApplicationSetup } from './models/ApplicationSetup';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useApplicationSetupService, { useBasicFilterApplicationSetup } from './services/ApplicationSetup';
import useApplicationQueryService, {useBasicFilterApplicationQuery} from './services/ApplicationQuery';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import {  IconButton, InputAdornment, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';


import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import md5 from 'md5';
import { IAppTheme, defaultAppTheme, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';
import { IBusinessApplication, IApplicationQuery } from './models/ApplicationQuery';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { isFalsy } from 'utility-types';
import { ChromePicker, ColorResult } from 'react-color';
import { GrDocumentDownload } from 'react-icons/gr';
import { ArrowDropUp } from '@mui/icons-material';

type QueryType = 'intermediary' | 'policy' | 'policyRisk' | 'policyGet' | 'person';

export const ApplicationSetupForm: FC<IApplicationSetup> = (props: IApplicationSetup = defaultApplicationSetup) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  

  const [_id, _setId] = useState<number>( Number( id || 999 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createApplicationSetup, updateApplicationSetup, getApplicationTheme, defineApplicationTheme,
    prompt
   } = useApplicationSetupService();

  //const {getBusinessApplications} = useApplicationQueryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterApplicationSetup = useBasicFilterApplicationSetup( 
    (event: React.MouseEvent<unknown>, row: IApplicationSetup) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const refCurrentBusinessApplicationId = useRef(0);

  const [expressionField, setExpressionField] = useState<keyof IApplicationSetup>('certificateDocumentExpression');      

  
  const methods = useForm<IApplicationSetup>({defaultValues:defaultApplicationSetup});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;


  const { register: registerTheme, setValue: setValueTheme ,getValues: getValuesTheme, watch: watchTheme, 
          reset: resetTheme ,control: controlThe , formState: { errors: errorsTheme } } = 
                useForm<IAppTheme>({defaultValues:defaultAppTheme});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //const watchCurrentSchoolYearId = watch('currentSchoolYearId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IApplicationSetup>,Error,IApplicationSetup>(
      _id>0?updateApplicationSetup:createApplicationSetup, {   
        onSuccess: (data: IResult<IApplicationSetup>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
                setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ApplicationSetup',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

  const {isLoading: isLoadingTheme, isError: isErrorTheme, isSuccess:isSuccessTheme, error: errorTheme, 
    mutate: mutateAppplicationTheme } = useMutation<IResult<boolean>,Error,IAppTheme>(
      defineApplicationTheme, {   
        onSuccess: (data: IResult<boolean>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
        
          //_setId(data.data.id);
          setOpenApplicationTheme(false);    
        },
        onError: (err: Error) => {          
          console.log(error);
          enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              setOpenApplicationTheme(false);
        }
      });

    const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);

    const {data: _data, refetch} = useQuery<IApplicationSetup>(['ApplicationSetup', _id], () => retrieveEntity('ApplicationSetup',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const watchWebhookToken = watch('webhookToken');
    const watchChatbotContext = watch('chatbotContext');
      
    const [currentQueryType, setCurrentQueryType] = useState<QueryType>('policy');
    const handleClickSearchApplicationQuery = (queryType: QueryType) => (event: any) => {

      //const {mainBusinessApplicationId} = getValues();

      // if(mainBusinessApplicationId < 0) {
      //   enqueueSnackbar( t('Business application is not selected'), { variant: 'error',
      //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      //   return;
      // }


      setCurrentQueryType(queryType);
      //setOpenApplicationQueryFilter(true);
    }

    const rand = () => {
      return Math.random().toString(36).substring(2);
    };

    const generateToken = (len: number) : string => {
      return (rand() + rand() + rand() + rand()).substring(0,len);
    };


    const handleClickGeneratApplicationApiToken = (event: any) => {
      setValue('applicationApiToken', generateToken(26));
    }


    function openFileDialog(  ) {
      (document as any).getElementById("file-upload").click();
      // const fileElementId = "documentFile";
      // var element = (document as any).getElementById(fileElementId);

      // if(!isFalsy(element))
      //   element.click();
    }

    const setFile = (_event: any) => {
      let f = _event.target.files![0];
      
      var reader = new FileReader();
      
      reader.onload = function () {

        var txt = reader.result as string;

        console.log(txt);
        setValue('chatbotContext', txt);        
      };

      reader.onerror = function () {
        console.log("File load failed");
      };
      
      reader.readAsText(f);
    }


    
    const handleDocumentDownload = async (event: any) => {
      const {id, chatbotContext} = getValues();

      const filename = 'ChatbotContext.txt';
      
      const blob = new Blob([chatbotContext], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }


    const handleClickGenerateWebhookToken = (event: any) => {
      setValue('webhookToken', generateToken(26));
    }

    const [promptAnswer, setPromptAnswer] = useState<string>('');
    const handleChatbotPrompt = async (event: any) => {
      const res = await prompt({prompt: getValues().currentChatbotPrompt, histories: []});

      setPromptAnswer(res);
      //alert(getValues().currentChatbotPrompt);
    }

    const [openEntityExpression, setOpenEntityExpression] = useState(false);
    const [currentExpression, setCurrentExpression] = useState(''); 
    const [currentEntityName, setCurrentEntityName] = useState(''); 
    const [currentReturnType, setCurrentReturnType] = useState(''); 

    type ExpressionType = 'proforma-number' | 'billing-number' | 'delivery-number' | 'chatbot';
    const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('proforma-number');

    const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
        entityName: string, returnType: string, expression: string) => {

      setCurrentExpressionType(expressionType);
      setCurrentEntityName(entityName);
      setCurrentReturnType(returnType);
      setCurrentExpression(expression);
      setOpenEntityExpression(true);
    }

    const handleClickOkExpression = async () => {

      
      const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
      if(!checkExpression){
        enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const {syntaxOk, syntaxError, returnType} = checkExpression;
      if(!syntaxOk) {
        enqueueSnackbar( syntaxError , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }
      
      if(returnType !== currentReturnType) {
        enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              return;
      }
    
      
      if( ['proforma-number', 'billing-number', 'delivery-number'].includes(currentExpressionType)  ) {

        const proformaNumberExpression = currentExpressionType === 'proforma-number' ? currentExpression: getValues().proformaNumberExpression;
        const billingNumberExpression = currentExpressionType === 'billing-number' ? currentExpression: getValues().billingNumberExpression;
        const deliveryNumberExpression = currentExpressionType === 'delivery-number' ? currentExpression: getValues().deliveryNumberExpression;
        
        setValue('proformaNumberExpression', proformaNumberExpression);
        setValue('billingNumberExpression', billingNumberExpression);
        setValue('deliveryNumberExpression', deliveryNumberExpression);        
      } else if( ['chatbot'].includes(currentExpressionType)) {
        
        setValue('chatbotActivityExpression', currentExpression);
      }

      setOpenEntityExpression(false);
    }
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Application Setup'));  
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);      
      }, []);   

      // useEffect( () => {
               
      //   // if(_id > 0)
      //   //   retrieveData('ApplicationSetup',_id, refetch);

      //     retrieveData('ApplicationSetup',999, refetch);  
      //   }, [/*_id*/] );

      // useEffect( () => {        
      //   refCurrentBusinessApplicationId.current = watchMainBusinessApplicationId;
      // }, [watchMainBusinessApplicationId]);    

      useEffect( () => {        
        setValue('webhookUrl', `${'_APP_'}/api/misc/hookData/v1/${watchWebhookToken}/receive-hookData` )
      }, [watchWebhookToken]);  
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
               
          // if(_id > 0)
          //   retrieveData('ApplicationSetup',_id, refetch);

            retrieveData('ApplicationSetup',77, refetch);  
          }, [/*_id*/] );

    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
            _setId(_data.id);
            reset(_data);
        }
        }, [_data]);
    
      
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => { 
        if(!checkEntitySaveAuthorization('ApplicationSetup', _id)){
          setIsSaveLoading(false);
             return;
        }
          
        const data = getValues(); 
        const {whatsAppPassword} = data;

        if(data.companyName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
           }
      
           console.log({data});
         mutate({...data, isPasswordChanged: whatsAppPassword !== '********' ,
         whatsAppPasswordMd5: md5(whatsAppPassword), whatsAppPassword: '',   });          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ApplicationSetup', _id);
      }

      const [isThemeColor, setIsThemeColor] = useState<boolean>(false);

      const [defColor, setDefColor] = useState<FieldPath<IAppTheme>>('background');
      const handleColorClick = (event: React.MouseEvent<HTMLElement>, defCol: FieldPath<IAppTheme> ) => {
        setIsThemeColor(true);
        setDefColor(defCol);
        setAnchorEl(event.currentTarget); 
      }

      const [defMsgColor, setDefMsgColor] = useState<FieldPath<IApplicationSetup>>('newMessageColor');
      const handleMsgColorClick = (event: React.MouseEvent<HTMLElement>, defMsgCol: FieldPath<IApplicationSetup> ) => {
        setIsThemeColor(false);
        setDefMsgColor(defMsgCol);
        setAnchorEl(event.currentTarget); 
      }

      const [openApplicationTheme, setOpenApplicationTheme] = useState<boolean>(false);
      const actionDataDefineThemeApplicationSetup = async (event: MouseEvent<HTMLButtonElement>) => {     
        const applicationTheme = await getApplicationTheme();   
                
        if(!isFalsy(applicationTheme)) 
          resetTheme(applicationTheme);

        setOpenApplicationTheme(true);
      }

      const handleOkDefineThemeApplicationSetup = async () => { 
        mutateAppplicationTheme(getValuesTheme());
      }

      const [genericTheme, setGenericTheme] = useState<string>('default');
      const handleClickApplyTheme = (event: any) => {
        switch(genericTheme) {
          case 'default': resetTheme(defaultAppTheme); break;

          default: resetTheme(defaultAppTheme);
        }
      }
      

      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }


  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />                                

                                <Button id='btnActionDefineThemeApplicationSetup' onClick={actionDataDefineThemeApplicationSetup} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('companyName')} inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Password complexity?')}`}
                                    control={
                                    <Controller
                                        name='isPasswordComplexityAllowed'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                                 
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Password duration')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Duration in days')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='passwordDuration'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Password rotation')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Number of differents')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='passwordRotation'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Max failed count')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Number of failures')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='maxAccessFailedCount'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Access lockout duration')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Duration in minutes')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='accessLockoutDuration'
                                control={control}
                              />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Visual identification of message')} `}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleMsgColorClick(event, 'newMessageColor')}
                                  sx={{ ml: 1, width: '24%', backgroundColor: getValues().newMessageColor, color: theme.palette.getContrastText(getValues().newMessageColor)}}>
                                  {t('New message')}
                              </Button>
                              <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleMsgColorClick(event, 'nonTicketableMessageColor')}
                                  sx={{ ml: 1, width: '24%', backgroundColor: getValues().nonTicketableMessageColor, color: theme.palette.getContrastText(getValues().nonTicketableMessageColor)}}>
                                  {t('Non ticketable')}
                              </Button>
                              <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleMsgColorClick(event, 'ticketableMessageColor')}
                                  sx={{ ml: 1, width: '24%', backgroundColor: getValues().ticketableMessageColor, color: theme.palette.getContrastText(getValues().ticketableMessageColor)}}>
                                  {t('Ticketable')}
                              </Button>
                              <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleMsgColorClick(event, 'joinedMessageColor')}
                                  sx={{ ml: 1, width: '24%', backgroundColor: getValues().joinedMessageColor, color: theme.palette.getContrastText(getValues().joinedMessageColor)}}>
                                  {t('Joined to ticket')}
                              </Button>
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Tickets configuration')} `}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Default time to resolve ticket')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Time in hours')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='defaultTicketDurationHours'
                                control={control}
                              />
                            </Box>
                            
                            
                            {Boolean(anchorEl) && <Popover
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => {setAnchorEl(null);}}
                            >
                              <ChromePicker 
                                  color={ isThemeColor? get(getValuesTheme(), defColor ) as unknown as string : get(getValues(), defMsgColor ) as unknown as string } 
                                  onChange={(newColor: ColorResult) => {
                                           if(isThemeColor) setValueTheme(defColor, newColor.hex); else setValue(defMsgColor, newColor.hex); }} 
                              />
                            </Popover> }
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                <EntityExpression entityName={currentEntityName} properties={[]} 
                                  expression={currentExpression} setExpression={setCurrentExpression} />
                              </FormDialog>  }
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Application API')} ... ${t('Integration')}`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(50% - 8px)'}} id="applicationApiToken" label={t('Token')} 
                                  {...register('applicationApiToken')} inputProps={ {autoComplete: 'new-password', 
                                      style: {textTransform: 'none'}}}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickGeneratApplicationApiToken}>
                                          <TokenIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />       
                                <TextField sx={{width:'calc(50% - 8px)'}} id="websiteApplicationApiToken" label={`${('Token')} (${t('Website')})`} 
                                  {...register('websiteApplicationApiToken')} inputProps={ {autoComplete: 'new-password', 
                                      style: {textTransform: 'none'}}}
                                />                          
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Chatbot')} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Is Chatbot On ?')}`}
                                    control={
                                    <Controller
                                        name='isChatbotOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                             
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} key={`currentExpression ${getValues().chatbotActivityExpression} `} >
                              <Controller
                                key={`chatbot-expression ${getValues().chatbotActivityExpression}`}
                                render={({ field }) => <TextField label={`${t('Chatbot')} : ${t('Expression')}`} multiline rows={3}
                                    {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'chatbot', 'ApplicationSetup', 'boolean', getValues().chatbotActivityExpression)}>
                                              <DeveloperModeIcon />
                                            </IconButton>                                                                              
                                        </InputAdornment>
                                    )
                                  }}
                                  sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                                  name={`chatbotActivityExpression`}                      
                                control={control}
                              />
                            </Box>
                            <Box sx={{ mt: 5, width: '100%' }} key={`currentprompt ${getValues().currentChatbotPrompt} `} >
                              <Controller
                                key={`chatbot-prompt ${getValues().currentChatbotPrompt}`}
                                render={({ field }) => <TextField label={`${t('Chatbot')} : Prompt`} multiline rows={3}
                                    {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton color="primary" onClick={handleChatbotPrompt}>
                                              <ArrowDropUp />
                                            </IconButton>                                                                              
                                        </InputAdornment>
                                    )
                                  }}
                                  sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                                  name={`currentChatbotPrompt`}                      
                                control={control}
                              />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" sx={{...typographyGroupBoxStyling}}>
                                {`${promptAnswer} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%', pt: 3 }} >
                              <Stack flexDirection='row'>
                                
                                <Box sx={{ mt: 1, width: '80%', pt: 0 }} key={`${watchChatbotContext}`} >
                                  <Button onClick={ () => openFileDialog()} id={`lg - chatbot context`} key={`${watchChatbotContext}`}>
                                      <input type="file" accept=".txt" id="file-upload" style={{ display: "none" }} onChange={ (_event) => setFile(_event) }/>
                                      {isFalsy(watchChatbotContext) ? `${t('Click to toad file')} ...${t('Context')}`: ` -> ${t('Click to change')}`}
                                  </Button>                     
                                </Box>
                                <Box sx={{ ...justifyCenter, mt: 1, width: '20%', pt: 0 }} >
                                  <Button>
                                    <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                      {`: :`}
                                    </Typography>
                                    { !isFalsy(watchChatbotContext) && <Box sx={{ ...justifyCenter, ml: 1 }} >                                                     
                                      <GrDocumentDownload size={24} onClick={handleDocumentDownload} />
                                    </Box> }
                                  </Button>
                                  
                                </Box>
                              </Stack>    
                          </Box>
                            
                            { openApplicationTheme && <FormDialog open={openApplicationTheme} maxWidth='sm' height='70vh'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Application theme')} ...`} onCancel={()=> {setOpenApplicationTheme(false);}} 
                                  onClose={()=> {setOpenApplicationTheme(false);}} onOk={handleOkDefineThemeApplicationSetup}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'background')}
                                        sx={{ ml: 1, width: '32%', backgroundColor: getValuesTheme().background, color: theme.palette.getContrastText(getValuesTheme().background)}}>
                                        {t('Background')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'primary')}
                                        sx={{ ml: 1, width: '30%', backgroundColor: getValuesTheme().primary, color: theme.palette.getContrastText(getValuesTheme().primary)}}>
                                        {t('Primary')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'secondary')}
                                        sx={{ ml: 1, width: '30%', backgroundColor: getValuesTheme().secondary, color: theme.palette.getContrastText(getValuesTheme().secondary)}}>
                                        {t('Secondary')}
                                    </Button>                                    
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                  <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridHeader')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridHeader, color: theme.palette.getContrastText(getValuesTheme().gridHeader)}}>
                                        {t('Grid header')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridAlt')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridAlt, color: theme.palette.getContrastText(getValuesTheme().gridAlt)}}>
                                        {t('Grid alt')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridActiveRow')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridActiveRow, color: theme.palette.getContrastText(getValuesTheme().gridActiveRow)}}>
                                        {t('Grid active row')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridSelectedRows')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridSelectedRows, color: theme.palette.getContrastText(getValuesTheme().gridSelectedRows)}}>
                                        {t('Grid selected rows')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'menuButton')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().menuButton, color: theme.palette.getContrastText(getValuesTheme().menuButton)}}>
                                        {t('Menu button')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'menuIcon')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().menuIcon, color: theme.palette.getContrastText(getValuesTheme().menuIcon)}}>
                                        {t('Menu icon')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'dark')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().dark, color: theme.palette.getContrastText(getValuesTheme().dark)}}>
                                        {t('Dark')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'light')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().light, color: theme.palette.getContrastText(getValuesTheme().light)}}>
                                        {t('Light')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'success')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().success, color: theme.palette.getContrastText(getValuesTheme().success)}}>
                                        {t('Success')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'info')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().info, color: theme.palette.getContrastText(getValuesTheme().info)}}>
                                        {t('Info')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'danger')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().danger, color: theme.palette.getContrastText(getValuesTheme().danger)}}>
                                        {t('Danger')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'warning')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().warning, color: theme.palette.getContrastText(getValuesTheme().warning)}}>
                                        {t('Warning')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'muted')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().muted, color: theme.palette.getContrastText(getValuesTheme().muted)}}>
                                        {t('Muted')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'border')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().border, color: theme.palette.getContrastText(getValuesTheme().border)}}>
                                        {t('Border')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'inverse')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().inverse, color: theme.palette.getContrastText(getValuesTheme().inverse)}}>
                                        {t('Inverse')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'shaft')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().shaft, color: theme.palette.getContrastText(getValuesTheme().shaft)}}>
                                        {t('Shaft')}
                                    </Button>
                                  </Box>
                                  <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 4.5, width: '100%' }} >                                     
                                      <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setGenericTheme(event.target.value);}} value={genericTheme} sx={{width:'calc(30% - 8px)'}} id="genericTheme"
                                            label={t('Generic theme')} inputProps={ {readOnly: false}} >
                                          <MenuItem value='default'>{t('Default')}</MenuItem>
                                          <MenuItem value='blue'>{t('Blue')}</MenuItem>
                                          <MenuItem value='green'>{t('Green')}</MenuItem>
                                          <MenuItem value='yellow'>{t('Yellow')}</MenuItem>
                                          <MenuItem value='red'>{t('Red')}</MenuItem>
                                          <MenuItem value='pink'>{t('Pink')}</MenuItem>
                                      </TextField>                                        
                                    <Button variant="outlined" onClick={handleClickApplyTheme} sx={{mb:2, ml: 1 }}>
                                      {t('Apply')}                              
                                    </Button>                                                   
                                  </Box>
                                  
                                </Stack>      
                              </FormDialog> } 
                        </Stack>                        
                    </Grid>                  
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

