
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';


import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import Button from '@mui/material/Button';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import MoreIcon from '@mui/icons-material/More';

import {IRole, defaultRole, IRoleEntity, IRoleEntityFeature } from './models/Role';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { FormDialog } from 'components/ui/FormDialog';
import { IEntity, IFeatureDescription, IResult } from 'library/interface';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useRoleService, { useBasicFilterRole } from './services/Role';
import EnhancedTable from 'components/ui/EnhancedTable';
import { isFalsy } from 'utility-types';





export const RoleForm: FC<IRole> = (props: IRole = defaultRole) => {
    
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const {id} = useParams();
    
    const [_id, _setId] = useState<number>( Number( id || 0 ) );

    const { enqueueSnackbar } = useSnackbar();
   
    const [roleEntityIndex, setRoleEntityIndex] = useState<number>(-1);

    const {retrieveEntity, retrieveData, openEntityActionDrawer, 
            checkEntitySaveAuthorization, getFeaturesByEntityType, getRootEntities} = useEntityService();

    const { createRole, updateRole, getRole, getRoles } = useRoleService();

    const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
    const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

    const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
    const basicFilterRole = useBasicFilterRole( 
        (event: React.MouseEvent<unknown>, row: IRole) => {
            setIsSearchBoxShow(false);
            _setId(row.id);
        }
    );

    const emptyFunc = (obj: any) => {}

    const [selectedFilterEntities, setSelectedFilterEntities] = useState<string[]>([]);
    const [filteredEntities, setFilteredEntities] = useState<IEntity[]>([]);

    const [openEntityFilter, setOpenEntityFilter] = useState(false);
    const basicFilterEntity = useBasicFilterEntity( 
        (event: React.MouseEvent<unknown>, row: IEntity) => {
            const {name, description, id} = row;

            if(getValues().roleEntities.some( b => b.entityName === name)) return;       
            
            (refAppendRoleEntities.current??emptyFunc)({roleId: _id, roleEntityId: id ,entityName: name, entityDescription: description, 
             canCreate: false, canRetrieve:false, canUpdate: false, printAllowed: false, workflowAllowed: false, attachAllowed: false,
             relativeViewAllowed: false, securityAllowed: false, linkAllowed: false, roleEntityFeatures: []     
            }); 
        
            setRoleEntityIndex( Math.max(0, getValues().roleEntities.length-1));        
            setOpenEntityFilter(false);
        }, {
            rowCheckedMode: 'multiple', 
            stateSelected: [selectedFilterEntities, setSelectedFilterEntities],
            stateFiltered: [filteredEntities, setFilteredEntities],
        }
    );

    const handleOkBasicTextFilterEntityForm = () => {
        if(selectedFilterEntities.length === 0) {
            // enque
            setOpenEntityFilter(false);
            return;
        } 

        const entities2Append = filteredEntities.filter(ne => selectedFilterEntities.includes(ne.name || '') &&
             !getValues().roleEntities.some(e => e.entityName === ne.name) );

        if(entities2Append.length === 0) return;

        (refAppendRoleEntities.current??emptyFunc)( entities2Append.map( ({name, description, id}) => 
            ({roleId: _id, roleEntityId: id , entityName: name, entityDescription: description, 
                canCreate: false, canRetrieve: false, printAllowed: false, workflowAllowed: false, attachAllowed: false, canUpdate: false,
                relativeViewAllowed: false, securityAllowed: false, linkAllowed: false,  roleEntityFeatures: []     
            })));

        setOpenEntityFilter(false);
    }


    const [selectedFilterFeatures, setSelectedFilterFeatures] = useState<string[]>([]);
    const [filteredFeatures, setFilteredFeatures] = useState<IFeatureDescription[]>([]);

    
    // const basicFilterFeature = useBasicFilterFeatureDescription(
    //     () => {
    //         if(roleEntityIndex < 0 || getValues().roleEntities.length <= roleEntityIndex ) return 'K--@@--K';  
    //         return getValues().roleEntities[roleEntityIndex].entityName;
    //     }, 
    //     (event: React.MouseEvent<unknown>, row: IFeatureDescription) => {
    //         const {name, label, entityName} = row;
  
    //         const roleEntity = getValues().roleEntities.find(b => b.entityName === entityName);
    //         if(!roleEntity) return;            
    
    //         if( roleEntity && !roleEntity.roleEntityFeatures.some(f => f.featureName === name) )
    //             (refAppendRoleEntityFeatures.current??emptyFunc)({featureName: name, featureDescription: label, roleEntityId: -1 });
            
    //         //setFilteredFeatures([]);
    //         setOpenEntityFeature(false);
    //     }, {
    //             rowCheckedMode: 'multiple', 
    //             stateSelected: [selectedFilterFeatures, setSelectedFilterFeatures],
    //             stateFiltered: [filteredFeatures, setFilteredFeatures],
    //     }
    // );

    
    const handleOkFeatureFilterClick = () => {
        
        
        const entityFeatures = features.filter(f => selectedFeatures.includes(f.name));
                
        setValue(`roleEntities.${roleEntityIndex}.roleEntityFeatures`, entityFeatures.map(({name, label}) => 
                ({id: 0, featureName: name, featureDescription: label, roleEntityId: -1})));
        setOpenEntityFeature(false);
    }

    const methods = useForm<IRole>({defaultValues:defaultRole});
    const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

    const queryClient = useQueryClient();
    const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IRole>,Error,IRole>(
        _id>0?updateRole:createRole, {   
            onSuccess: (data: IResult<IRole>) => {
                enqueueSnackbar( 'Operation done !!!', { variant: 'success',
                        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                        
                setIsSaveLoading(false);
                _setId(data.data.id);
                //setCurrentEntityIdForAction(data.data.id);
            
            queryClient.invalidateQueries(['Role',data.data.id]);
            },
            onError: (err: Error) => {          
                enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                setIsSaveLoading(false);
            }
        });

    const {data: _data, refetch} = useQuery<IRole>(['Role', _id], () => retrieveEntity('Role',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const [headRoleEntityCells, setHeadRoleEntityCells]  = useState<HeadCell<IRoleEntity>[]>([      
        {id:'id', label : t('Id'),  display: false, type: 'string', },
        {id:'entityName', label : t('Name'),  display: false, type: 'string', },
        {id:'entityDescription', label : t('Description'),  display: true, type: 'string', },
        {id:'canCreate', label : t('Create ?'),  display: true, type: 'boolean', },
        {id:'canRetrieve', label : t('Visualize ?'),  display: true, type: 'boolean', },
        {id:'canUpdate', label : t('Update ?'),  display: true, type: 'boolean', },
        {id:'printAllowed', label : t('Print ?'),  display: true, type: 'boolean', },
        {id:'attachAllowed', label : t('Attach ?'),  display: true, type: 'boolean', },
        {id:'relativeViewAllowed', label : t('Rel. view?'),  display: false, type: 'boolean', },
      ]);
    const refAppendRoleEntities = useRef<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateRoleEntity = useRef<(index: number,value: Partial<FieldArray<IRole>> ) => void>(null);
    const refRemoveRoleEntity = useRef<(index: number ) => void>(null);

    const [selectedRoleEntities, setSelectedRoleEntities] = useState<string[]>([]);

    const handleAddEntities = (event: any) => {
        setOpenEntityFilter(true);
    }

    const handleAddAllEntities = async (event: any) => {
        const entities = await getRootEntities({name: '', description: ''});

        const entities2Append = entities.filter(ne => !getValues().roleEntities.some(e => e.entityName === ne.name) );

        if(entities2Append.length === 0) return;

        (refAppendRoleEntities.current??emptyFunc)( entities2Append.map( ({name, description, id}) => 
            ({roleId: _id, roleEntityId: id , entityName: name, entityDescription: description, 
                canCreate: false, canRetrieve: false, printAllowed: false, workflowAllowed: false, attachAllowed: false, canUpdate: false,
                relativeViewAllowed: false, securityAllowed: false, linkAllowed: false,  roleEntityFeatures: []     
            })));
    }

    const handleRemoveAllEntities = (event: any) => {
        setValue('roleEntities', []);
    }

    const handleSelectAllFeatures = (event: any) => {
        const { roleEntities } = getValues();
        setValue('roleEntities', roleEntities.map( re => ({...re, 
                canCreate: true, canRetrieve: true, canUpdate: true, 
                linkAllowed: true, printAllowed: true, attachAllowed: true}) ), {shouldValidate: true});
    }

    const handleUnselectAllFeatures = (event: any) => {
        const { roleEntities } = getValues();
        setValue('roleEntities', roleEntities.map( re => ({...re, 
                canCreate: false, canRetrieve: false, canUpdate: false, 
                linkAllowed: false, printAllowed: false, attachAllowed: false}) ), {shouldValidate: true});
    }

    const handleRoleEntitySelected = (event: React.MouseEvent<unknown>,index: number,row: IRoleEntity) => {      
        setRoleEntityIndex(index);
    }

    const roleEntityRowActionIcon = ( roleEntity: IRoleEntity) : ActionIconTableRow<IRole,IRoleEntity> => {
  
        const res: ActionIconTableRow<IRole,IRoleEntity> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, /*field: FieldArrayWithId<IBilling, ArrayPath<IBilling>, string> ,*/row: IRoleEntity) => {
            
             (refRemoveRoleEntity.current??emptyFunc)(index);            
          }
        }
        return res;
    }


    const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);

    const [openEntityFeature, setOpenEntityFeature] = useState(false);
    const [features, setFeatures] = useState<IFeatureDescription[]>([]);
    const roleEntityMenuRowActionIcon = (row: IRoleEntity) => ({
        icon: MoreVertIcon,
        rowActionIcons: [
          {
            toolTip: t('View features and actions'),
            icon: MoreIcon, 
            hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
            isActionExecuting: true,
            onRowClickIcon: async (event : any,index: number, row: IRoleEntity) => {
              
                const {entityName} = row;
                setRoleEntityIndex(index);

                const roleEntity = getValues().roleEntities.find(re => re.entityName === entityName);
                if(isFalsy(roleEntity)) return;
                
                const arr = await getFeaturesByEntityType(entityName, {featureName: '', featureDescription: ''});
                setFeatures(arr);
                setOpenEntityFeature(true);

                console.log({features, row, index, ent: getValues().roleEntities[index]});

                setSelectedFeatures(roleEntity.roleEntityFeatures.map(x => x.featureName));

            //   setValue('currentItemIndex', index);
            //   setOpenCargoItemMore(true);                        
            }
          },
          {
            toolTip: 'Remove',
            icon: RemoveCircleIcon,
            hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
            isActionExecuting: true,
            onRowClickIcon: (event : any,index: number, row: IRoleEntity) => {
                
                (refRemoveRoleEntity.current??emptyFunc)(index);                        
            }
          }
          
        ]      
      })

    

    
    const [headRoleEntityFeatureCells, setHeadRoleEntityFeatureCells]  = useState<HeadCell<IRoleEntityFeature>[]>([      
        {id:'id', label : t('Id'),  display: false, type: 'string', },
        {id:'featureName', label : t('Name'),  display: true, type: 'string', },
        {id:'featureDescription', label : t('Description'),  display: true, type: 'string', },
      ]);
    const refAppendRoleEntityFeatures = useRef<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateRoleEntityFeature = useRef<(index: number,value: Partial<FieldArray<IRole>> ) => void>(null);
    const refRemoveRoleEntityFeature = useRef<(index: number ) => void>(null);

    const handleAddFeatures = (event: any) => {
        if(roleEntityIndex < 0 || getValues().roleEntities.length <= roleEntityIndex ) return;
        //setOpenEntityFeature(true);
      }

      const roleEntityFeatureRowActionIcon = ( roleEntity: IRoleEntityFeature) : ActionIconTableRow<IRole,IRoleEntityFeature> => {
  
        const res: ActionIconTableRow<IRole,IRoleEntityFeature> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, /*field: FieldArrayWithId<IBilling, ArrayPath<IBilling>, string> ,*/row: IRoleEntityFeature) => {
            
             (refRemoveRoleEntityFeature.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    
      useEffect( () => {              
        setCurrentFormNameAtom(t('Role'));  
        setCurrentBasicTextFilterProps(basicFilterRole);
      }, []);


      /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
        // setCurrentFormName(t('Billing'));        
        
        if(_id > 0)
          retrieveData('Role',_id, refetch);  
      }, [_id] );


    useEffect( () => {
       
        if(_data && _data.id > 0) {
        reset(_data);

        setRoleEntityIndex(0);  
    }
    }, [_data]);

  const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
    _setId(0);           
    reset(defaultRole);    
  }
  
  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
    
    if(!checkEntitySaveAuthorization('Role', _id)) {
        setIsSaveLoading(false);         
        return;
      }

      const data = getValues(); 
      if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }
  
      mutate(data);
  }

  const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
    openEntityActionDrawer('Role', _id);
  }
  
  
  
  const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
  //    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
  //    reset(_data);        
   }
    

    return (
        <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box  sx={{ mt: 1, width: '100%' }} >
                                {/* <legend>Jean-François H</legend> component="fieldset"*/}
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(35% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                                <TextField sx={{width:'calc(55% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IRole,IRoleEntity,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headRoleEntityCells} rowsPathName='roleEntities' 
                                    title={t('Functions')} 
                                    //rowActionIcon={roleEntityRowActionIcon}  
                                    rowMenuActionIcon={roleEntityMenuRowActionIcon}
                                    onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendRoleEntities as MutableRefObject<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateRoleEntity as MutableRefObject<(index: number,value: Partial<FieldArray<IRole>>) => void>}
                                    refRemove={refRemoveRoleEntity as MutableRefObject<(index: number) => void>}

                                    stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                        { toolTip: `${t('Add function')}...`, onClickIcon: handleAddEntities ,icon: AddCircleIcon,  },
                                        { toolTip: `${t('Add all functions')}...+++`, onClickIcon: handleAddAllEntities ,icon: LibraryAddIcon,  },

                                        { toolTip: `${t('Remove all functions')}...---`, onClickIcon: handleRemoveAllEntities ,icon: DeleteSweepIcon,  },
                                        { toolTip: `${t('Select all features and actions')}...+++`, onClickIcon: handleSelectAllFeatures ,icon: SelectAllIcon,  },
                                        { toolTip: `${t('Unselect all features and actions')}...+++`, onClickIcon: handleUnselectAllFeatures ,icon: CheckBoxOutlineBlankIcon,  },
                                       

                                    ]}
                                />
                                { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={handleOkBasicTextFilterEntityForm}  >
                                        <BasicTextFilterForm<IEntity> {...basicFilterEntity } />
                                </FormDialog> }
                                { openEntityFeature && <FormDialog open={openEntityFeature} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={`${t('Features')} ... Config...`} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFeature(false);}} onOk={handleOkFeatureFilterClick}  >
                                        {/* <BasicTextFilterForm {...basicFilterFeature} /> */}
                                        <Stack flexDirection='column'> 
                                            <Box sx={{ mt: 1, width: '100%' }} >
                                                <EnhancedTable<IFeatureDescription> 
                                                    rows={features} 
                                                    headCells={[            
                                                    
                                                    {id:'label', label : t('Name'),  display: true, type: 'string', width: 100,  },
                                                    //{id:'date', label : t('Date'),  display: true, type: 'date', width: 25,  },
                                                    
                                                    ]} 
                                                    title={t('Features')} objKey={'name'} 
                                                    rowCheckedMode='multiple'
                                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                                    orderBy={'name'}

                                                    stateSelected={[selectedFeatures, setSelectedFeatures]}
                                                />                        
                                            </Box>
                                    </Stack>
                                </FormDialog> }
                            </Box>
                        </Stack> 
                    </Grid>
                    {/* <Grid item xs={12}  md={4} component={Paper} >
                        <Stack flexDirection='column'>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                            {
                                getValues().roleEntities.map( (roleEntity,index) => {
                                
                                    return (index === roleEntityIndex) && <ArrayFieldTableEx<IRole,IRoleEntityFeature,"id"> 
                                    key={`roleEntity-${index}`}
                                    rowsPathName={`roleEntities.${index}.roleEntityFeatures`}
                                    headCells={headRoleEntityFeatureCells}  fieldKey='id'                     
                                    title={`${t('Features')} : ${roleEntity.entityDescription} `} rowActionIcon={roleEntityFeatureRowActionIcon}
                                    refAppend={refAppendRoleEntityFeatures as MutableRefObject<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateRoleEntityFeature as MutableRefObject<(index: number,value: Partial<FieldArray<IRole>>) => void>}
                                    refRemove={refRemoveRoleEntityFeature as MutableRefObject<(index: number) => void>}

                                    //onRowSelected={handleBillingDetailTaskSelected}
                                    //displayMore={undefined}
                                    
                                    toolbarActions={[
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddFeatures ,icon: AddCircleIcon,  },
                                        
                                        ]} 
                                
                                    /> 
                                } )  
                            }
                            
                            </Box>
                        </Stack> 
                    </Grid> */}
                </Grid>
            </Box>
        </FormProvider>       
    );
}